.artist-view-wrapper {
  background: #101010;
}

.back-btn-wrp {
  padding: 20px 0;
}

.back-btn-wrp a {
  display: flex;
  align-items: center;
  color: #A667FC;
  font-size: 13px;
  text-decoration: none;
}

.back-btn-wrp img {
  width: 30px;
}
.artist-view-wrapper .left-side-wrp{
  padding-right: 30px;
}
.artist-view-wrapper .left-side-wrp .MagnifySlider .slick-slider {
  height: 765px;
}
.artist-view-wrapper .art-details {
  margin-bottom: 15px;
}
.art-details {
  padding: 10px 0;
}

.art-details p {
  display: flex;
  align-items: center;
  margin: 0;
}
.artist-view-wrapper .art-details p:not(:last-child){
  margin-bottom: 15px;
}
.art-details p img {
  width: 20px;
  margin-right: 5px;
}

.artist-view-wrapper .artist-block.f-wrp {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0 40px;
  flex-direction: column;
}
.artist-view-wrapper .artist-block.f-wrp .btn-wrap ul{
  justify-content: center;
}
.artist-view-wrapper .artist-block.f-wrp .btn-wrap ul li span{
  margin-top: 0;
}
.artist-view-wrapper .artist-block.f-wrp .btn-wrap ul .block-btn{
  background: #8C36FF;
}
.artist-view-wrapper .artist-block .artist-img {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.artist-view-wrapper .artist-block .artist-details h4 {
  font-size: 18px;
}
.artist-view-wrapper .category-slider .slick-slider{
  padding-left: 0;
}
.artist-view-wrapper .artist-block .artist-details p {
  font-size: 14px;
  margin: 0;
}

.artist-view-wrapper .artist-view-wrapper .category-slider{
  padding-bottom: 35px;
}

.artist-view-wrapper .category-slider .chip-btn {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 8px;
  width: max-content;
  background: #520074;
  text-decoration: none;
}

.artist-view-wrapper .each-categry-btn {
  padding-right: 20px;
  padding-left: 0;
}

.artist-view-wrapper .category-slider .chip-btn img {
  margin-right: 10px;
  width: 25px;
}


.artist-view-wrapper .art-tag-btn ul li {
  float: left;
}

.artist-view-wrapper .art-tag-btn ul li:not(:last-child) {
  margin-right: 20px;
}

.artist-view-wrapper .art-tag-btn .chip-btn {
  padding: 8px 20px;
  background: #8C36FF;
  font-size: 13px;
  display: inline-block;
  width: max-content;
  border-radius: 50px;
  transition: 0.5s all;
  margin-right: 20px;
}

.artist-view-wrapper .art-tag-btn .chip-btn:hover,
.artist-view-wrapper .art-tag-btn .chip-btn:active {
  background: #fff;
  color: #8C36FF;
  cursor: pointer;
  transition: 0.5s all;
}

.artist-view-wrapper .image-detail-wrp {
  padding: 50px 0 20px;
}

.artist-view-wrapper .image-detail-wrp h5 {
  font-size: 18px;
  margin-bottom: 15 px;
}

.artist-view-wrapper .image-detail-wrp p {
  /* margin-bottom: 0; */
}

.artist-view-wrapper .image-detail-wrp p b {
  font-weight: normal;
  color: #AEACAC;
}

.artist-view-wrapper .btn-wrap ul {
  display: flex;
  gap: 20px;
}

.artist-view-wrapper .btn-wrap .block-btn {
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  max-width: 184px;
  display: block;
  min-height: 50px;
  text-align: center;
  border-radius: 5px;
  margin-top: 40px;
  font-weight: 400;
  cursor: pointer;
  min-width: 150px;
}

.artist-view-wrapper .btn-wrap .block-btn.initial-btn {
  background: #4C2AA6;
}

.artist-view-wrapper .btn-wrap .block-btn.buy-btn {
  background: #8C36FF;
}

.artist-view-wrapper .price-detail-sec h3 {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
}

.artist-view-wrapper .price-detail-sec h3 img {
  width: 30px;
  margin-right: 10px;
  height: 25px;
}

.artist-view-wrapper .price-detail-sec h3 b {
  font-size: 12px;
  padding-right: 8px;
  padding-left: 12px;
}



.artist-view-wrapper .right-content-sec.f-wrp {
  padding-left: 60px;
}

.artist-view-wrapper .profile-card-wrp{
  background: linear-gradient(167deg, #1C0128 30.25%, rgba(22, 2, 30, 0.00) 85.52%);
  padding: 30px;
  margin-bottom: 20px;
}
.artist-view-wrapper .profile-card-wrp .profile-card-header p i{
  font-style: normal;
  font-size: 12px;
}
.artist-view-wrapper .profile-card-header h2 p{
  font-size: 22px;
  margin: 0;
}
.artist-view-wrapper .profile-card-header h2 .icon{
  background: none;
  border: none;
}
.artist-view-wrapper .profile-card-header h2 span.block-btn.initial-btn{
  font-size: 15px;
  line-height: 26px;
  margin-top: 0;
}
.artist-view-wrapper .profile-card-header ul{
  margin-bottom: 0;
}
.artist-view-wrapper .profile-card-con a{
  color: #A667FC;
}

.artist-view-wrapper .profile-card-con p {
  font-size: 14px;
}

.artist-view-wrapper .art-gallery-wrp ul{
  column-count: 2;
}
.artist-view-wrapper .art-gallery-wrp ul li{
  margin-bottom: 10px;
}
.art-main-wrapper .left-side-wrp .slider-sec .slick-prev::before,
.art-main-wrapper .left-side-wrp .slider-sec .slick-next::before{
    width: 21px;
    height: 33px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.art-main-wrapper .left-side-wrp .slider-sec .slick-prev::before{
  content: '';
  background-image: url(../../../assets/images/icons/prev.svg);
}
.art-main-wrapper .left-side-wrp .slider-sec .slick-next::before{
  content: '';
  background-image: url(../../../assets/images/icons/next.svg);
}

.profile-card-header h2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-card-header .share-btn-box{
  display: flex;
  width: auto;
  gap: 15px;
}
.profile-card-header .share-btn-box button{
  padding: 0;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
}

.artist-view-wrapper .social-block{
  padding-bottom: 25px;
}

.artist-view-wrapper .social-block .social-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.artist-view-wrapper .social-block .social-head p{
  margin: 0;
  
}
.artist-view-wrapper .social-block .social-head ul{
  margin: 0;
  display: flex;
  gap: 10px;
}
.artist-view-wrapper .social-block .social-head ul li .social-icon{
  display: block;
  width: 45px;
  height: 45px;
}
.artist-view-wrapper .profile-card-con h4{
  margin-top: 0;
  font-size: 18px;
}

.artist-view-wrapper .category-wrapper{
  padding-bottom: 25px;
}
