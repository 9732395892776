.banner-header {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  margin-left: 48px;
  /* son 64 total */
}

.carousel {
  max-width: 1016px;
  margin: 56px auto;
}

.banner__title {
  position: absolute;
  z-index: 999;
  top: 230px;
  /* son 488 */
  margin-left: 72px;
}

.chips {
  width: 120px;
  height: 40px;
  background: var(--primary);
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-family: Roboto;
}

.chips-container {
  display: flex;
  gap: 1rem;
}

.banner-text {
  /* font-family: inter; */
  font-weight: 700;
  font-size: 50px;
  line-height: 72px;
  font-family: Inter;
}

.see-button {
  margin-top: 2.5rem;
  background-color: var(--white);
  color: var(--black);
  width: 184px;
  height: 48px;
  font-size: 16px;
  font-family: "Red Hat Text";
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-decoration: none;
}
.see-button svg{
  position: relative;
  left: 0;  
}
.see-button:hover,
.see-button:focus {
  cursor: pointer;
  color: black;
}

.see-button:hover svg{
  animation: arrowAnimateLeft 1s infinite;
}

@keyframes arrowAnimateLeft {
  0% {
      left: 0px;
  }
  100% {
      left: 10px;
  }
}

.description {
  /* margin: 32px 0 140px 72px; */
  font-family: Roboto;
  font-weight: 300;
  /* line-height: 48px; */
  font-size: 20px !important;
}

.col-align,
.col-align-r {
  /* justify-content: center; */
  margin: 0 auto;
  width: 488px;
}

.col-align-r {
  width: 580px;
}

.col-title {
  font-family: Red Hat Text;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 56px;
}

.left-col-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 400;
}

.right-col-text {
  font-family: Red Hat Text;
  font-size: 19px;
  font-weight: 400;
}

.right-col-text--s {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 300;
}

.right-col-text span {
  display: inline;
  width: auto;
}

.project-wrapper {
  padding-top: 95px;
}

.project-wrapper .banner-header {
  display: none
}

.project-banner-wrapper.f-wrp {
  padding-bottom: 75px;
}

.project-sub-banner.f-wrp {
  display: flex;
  min-height: 500px;
  align-items: center;
  padding: 25px;
}

.project-sub-banner .banner__title {
  position: relative;
  margin: 0;
  top: unset;
}

.project-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.project-bg img {
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.project-sub-banner .banner__title .banner-text {
  text-shadow: 0px 0px #000;
}

.project-bg .slick-slider .slick-arrow {
  height: 100%;
  top: 0;
  transform: translate(0, 0%);
  width: 30px;
  background-color: #0000004a;
  z-index: 9;
}

.project-bg .slick-slider .slick-prev {
  left: 0px;
}

.project-bg .slick-slider .slick-next {
  right: 0px;
}

.project-switch-wrp ul {
  display: flex;
  justify-content: space-between;
}

.project-switch-wrp li a {
  text-decoration: none;
  display: block;
}

.project-switch-wrp label {
  display: block;
  color: #AEACAC;
}

.project-switch-wrp ul li:last-child {
  text-align: right;
}

.project-switch-wrp ul li:last-child .longArrow {
  transform: rotate(180deg);
}

.project-switch-wrp span {
  display: inline-block;
  width: auto;
}

.project-switch-wrp li,
.project-switch-wrp li span svg path,
.project-switch-wrp label {
  transition: 0.5s all;
  cursor: pointer;
  font-size: 18px;
}

.project-switch-wrp li:hover {
  transform: scale(1.1);
  transition: 0.5s all;
  cursor: pointer;
}

.project-switch-wrp li:hover label {
  color: #fff;
  transition: 0.5s all;
}

.project-switch-wrp li span svg {
  width: 35px;
  padding-left: 5px;
  display: inline-block;
}

.project-switch-wrp li:hover span svg path {
  stroke: #fff;
  transition: 0.5s all;
}

.project-discription-wrp.f-wrp {
  padding: 75px 0;
}

/* main project page */
.project-main-wrapper {
  background: #101010;
  padding-bottom: 105px;
  padding-top: 110px;
}

.project-main-wrapper .banner-header {
  margin-bottom: 75px;
  margin-left: 0;
}

.each-project-block {
  margin-bottom: 30px;
  border-radius: 2px;
  overflow: hidden;
}

.proj-title-blk.f-wrp {
  background: #fff;
  color: #000;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.proj-title-blk.f-wrp label {
  color: #000;
  display: block;
  font-weight: 700;
  font-size: 18px;
}

.proj-title-blk.f-wrp .see-button {
  background: #101010;
  color: #ffff;
  border: 1.5px solid transparent;
  width: auto;
  display: inline-block;
  height: auto;
  padding: 7px 30px;
  border-radius: 2px;
  font-size: 13px;
  margin-top: 0;
  text-align: center;
  white-space: nowrap;
  transition: 0.5s all;
}

.each-project-block>span {
  height: 250px;
  width: 100%;
  position: relative;
}



.each-project-block>span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
  transition: 1s all;
}

.each-project-block:hover>span img {
  transform: scale(1.07);
  transition: 1s all;
}

.each-project-block>span p {
  position: absolute;
  bottom: 0;
  transition: 0.5s all;
  margin: 0;  
  font-size: 14px;
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
  height: max-content;
  color: #fff;
  
  /* text-shadow: 1px 1px #fff; */
}
.each-project-block>span p b{
  padding: 20px;
  padding-top: 40px;
  display: block;
  position: relative;
  z-index: 1;
  font-weight: 500;
}
.each-project-block:hover>span p {
  transition: 0.5s all;
  max-height: 250px;
  transition: max-height 1s;
}

.each-project-block>span p::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  /* border: 2px solid #333; */
  /* filter: blur(5px); */
  /* -webkit-filter: blur(5px); */
  width: 100%;
  height: 100%;
  transition: 0.5s all;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.each-project-block:hover>span p::before {
  transition: 0.5s all;
}

.each-project-block:hover .proj-title-blk.f-wrp .see-button{
  background: #fff;
  color: #101010;
  border: 1.5px solid #101010;
  transition: 0.5s all;
  border-radius: 5px;
}

@media screen and (max-width: 991px) {
  .project-wrapper {
    padding-top: 0px;
  }
  .project-main-wrapper{
    padding-top: 10px;
  }
  .project-main-wrapper .banner-header{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .project-sub-banner .banner__title .banner-text {
    font-size: 32px;
    line-height: 1.5;
  }

  .chatbot-project-wrp .project-bg {
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 567px) {
  .project-sub-banner .banner__title .banner-text {
    font-size: 26px;
    line-height: 1.5;
  }
}