.blog-banner-wrapper {
  background: #2C1D38;
  padding-top: 110px;
}
@media screen and (max-width: 768px) {
  .blog-banner-wrapper {
    padding-top: 0px;
  }
}

.blog-main-wrapper .inner-sec-wrp {
  background: #2C1D38;
}

.blog-main-wrapper .inner-sec-wrp.nobg {
  background: transparent !important;
}

.blog-banner-content.f-wrp {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-top: 65px;
  padding-bottom: 50px;
}

.blog-banner-wrapper .banner-txt.f-wrp {
  padding-top: 0px;
}

.blog-banner-wrapper .banner-txt.f-wrp .main-title {
  font-size: 48px;
  padding-bottom: 30px;
  font-family: Red Hat Display;
  font-weight: 700;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .blog-banner-wrapper .banner-txt.f-wrp .main-title {
    text-align: left;
  }
}

.blog-tag-btn{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.blog-tag-btn .chip-btn{
  margin-right: 0px;
  background-color: #481b82;
}
.blog-tag-btn .chip-btn:hover{
  background-color: #481b82;
  color: #fff;
}
.blog-grid-wrp.f-wrp {
  padding: 10px 0 50px;
}

.each-blog-blk.f-wrp {
  padding: 15px;
}

.each-blog-blk.f-wrp p {
  font-size: 18px;
  font-weight: 300;
}

.blog-grid-wrp .each-blk-sec {
  /* height: 100%; */
  padding-bottom: 30px;
}

.each-blog-blk {
  background: #fff;
  width: calc(100% - 0px);
  margin: 0 auto;
  float: none;
  height: 100%;
  cursor: pointer;
}
.each-blog-blk .blog-img{
  width: 100%;
  height: 225px;
}

.each-blog-blk .flex-justify-wrp{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 225px);
}

.each-blog-blk .blog-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.each-blog-blk .blog-tag-btn{
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0;
}

.each-blog-blk h5{
  color: #1C1A22;
  padding: 10px 0 0px;
}
.each-blog-blk .basic-details{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 15px;
  padding-top: 10px;
}
.each-blog-blk .basic-details p{
  color: #AEACAC;
  margin: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.each-blog-blk .getintouch {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;
}
.each-blog-blk .getintouch span{
  color: #8C36FF;
  white-space: nowrap;
}

.each-blog-blk .getintouch .longArrow {
  transform: rotate(180deg);
  padding-right: 10px;
  align-self: center;
  height: 31px;
  width: 35px;
  overflow: hidden;
}

.each-blog-blk .getintouch .longArrow svg{
  width: 25px;
  position: relative;
}
.each-blog-blk:hover .getintouch .longArrow svg{
  animation: arrowMove2 1.5s infinite;
}
@keyframes arrowMove2 {
  0% {
      right: -26px;
  }

  100% {
      right: 26px;
  }

}

.blog-detail-wrp.f-wrp p{
  color: #101010;
  font-size: 1rem;
  font-weight: normal;
}

.blog-detail-wrp.f-wrp p span{
  color: #101010;
}

.blog-detail-wrp.f-wrp .basic-details{
  justify-content: flex-start;
  gap: 10px;
}
.blog-detail-wrp.f-wrp .basic-details p{
  color: #434343;
}
.blog-detail-wrp.f-wrp .basic-details p svg{
  width: 18px;
}
.blog-detail-wrp.f-wrp .basic-details p svg path{
  fill: #434343;
}
.each-blog-blk h4{
  color: #101010;
}
.blog-detail-popup{
  z-index: 99999999;
}
.blog-detail-popup .modal-header{
  border-bottom: none;
}
.blog-detail-popup .modal-body{
  padding-top: 10px;
}
.blog-detail-popup .modal-body .blog-detail-wrp.f-wrp{
  padding: 0;
  cursor: auto;
}
.blog-detail-popup .modal-body .each-blog-blk .blog-img{
  height: 100%;
  padding-bottom: 20px;
}
.blog-detail-popup .modal-body .blog-detail-wrp.f-wrp .basic-details{
  padding-bottom: 15px;
  padding-top: 0;
}

.close-btn-head {
  justify-content: flex-end;
  flex-direction: row-reverse;
  border: none;
  padding: 0;
  padding-top: 20px;
  padding-left: 20px;
  width: max-content;
  height: 40px;
}

.close-btn-head .modal-title {
  font-size: 14px;
  font-weight: 300;
  color: #520074;
}

.close-btn-head button.btn-close {
  padding: 0;
  background: none;
  border-radius: 0;
  opacity: 1;
  height: 20px;
  width: 50px;
  margin-right: 3px;
  position: relative;
}

.close-btn-head button.btn-close::after {
  content: 'Back';
  padding-left: 15px;
  font-size: 15px;
}

.close-btn-head button.btn-close::before {
  content: '';
  transform: rotate(-45deg);
  height: 10px;
  width: 10px;
  border-left: 2px solid #520074;
  border-top: 2px solid #520074;
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
}
@media (min-width: 992px) {
  .blog-detail-popup .modal-lg, .blog-detail-popup .modal-xl {
      max-width: 1200px;
  }
}

