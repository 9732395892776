.project-wrapper {
  background-color: #000;
}

.PPplatform-project-wrapper .project-sub-banner::after {
  /* content: ''; */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.mob-bg {
  width: 625px;
  height: calc(100% + 45px);
  position: absolute;
  left: 0;
  top: 0;
}

.mob-bg img {
  object-fit: contain;
  object-position: center;
  height: 100%;
}

/* .mob-bg::after{
  content: '';
  position: absolute;
  right: 272px;
  top: 50px;
  width: 195px;
  height: 397px;
  border: 2px solid red;
  display: block;
  border-radius: 20px;
  overflow: hidden;
} */
.PPplatform-project-wrapper .soc-slider-wrp.f-wrp {
  width: 200px;
  height: 422px;
  position: absolute;
  right: 48px;
  top: 59px;
  border-radius: 20px;
  overflow: hidden;
}

.PPplatform-project-wrapper .soc-slider-wrp.f-wrp::after,
.PPplatform-project-wrapper .soc-slider-wrp.f-wrp::before {
  content: '';
  display: block;
  width: 100%;
  height: 15px;
  position: absolute;
  left: 0;
  z-index: 9;
  background-repeat: no-repeat;

}

.PPplatform-project-wrapper .soc-slider-wrp.f-wrp::before {
  bottom: unset;
  top: 0;
  background-image: url('../../../../assets/images/projects/mobile-top.png');
  background-position: top;
  background-size: 120px;
}

.PPplatform-project-wrapper .soc-slider-wrp.f-wrp::after {
  top: unset;
  bottom: 0;
  background-image: url('../../../../assets/images/projects/mobile-bottom.png');
  background-position: center;
  background-size: 68px;
  width: 100%;
}

.PPplatform-project-wrapper .project-sub-banner .project-bg div {
  height: 100%;
}

.PPplatform-project-wrapper .soc-slider-wrp .slick-slider,
.PPplatform-project-wrapper .soc-slider-wrp .slick-slider div {
  height: 100%;
}

.PPplatform-project-wrapper .soc-slider-wrp .slick-slider div img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.PPplatform-project-wrapper .phone-wrp .slick-slider {
  height: 100%;
}

.response-bg {
  display: none;
}

.project-wrapper {
  background-color: #000;
}
.laptop {
  opacity: 1;
  width: 100%;
  height: 80%;
  max-height: calc(100vh + 100px);
  object-fit: contain;
  object-position: center;
}
.icon {
  width: 24px;
  display: inline;
  position: relative;
  animation: mapPointpulse 5s infinite;
}

.col-align {
  float: right;
}

.col-align-r {
  float: left;
}

.col-align,
.col-align-r {
  max-width: 660px;
  padding: 85px 30px 85px;
  margin: 0 0;
  width: 100%;
}
.left-col-text, .right-col-text--s {
  font-size: 19px;
}



.PPplatform-project-wrapper .project-switch-wrp label{
  color: rgba(33, 37, 41, 0.6);
}
.PPplatform-project-wrapper .project-switch-wrp li span svg path{
  stroke: rgba(33, 37, 41, 0.6);
}
.PPplatform-project-wrapper .project-switch-wrp li:hover label{
  color: rgba(33, 37, 41, 1);
}
.PPplatform-project-wrapper .project-switch-wrp li:hover span svg path{
  stroke: rgba(33, 37, 41, 1);
}






@media (max-width: 1200px) {
  .PPplatform-project-wrapper .mob-bg {
    width: 970px;
  }

  .PPplatform-project-wrapper .phone-sec-wrp.mob-slider-bg.f-wrp {
    width: 720px !important;
  }

  .PPplatform-project-wrapper .soc-slider-wrp.f-wrp {
    right: 39px;
    z-index: 9;
    height: 423px;
    top: 58px
  }
}

@media (min-width: 992px) {
  .PPplatform-project-wrapper .phone-sec-wrp.mob-slider-bg {
    right: 70px !important;
  }
}
@media (min-width: 1200px) {
  .PPplatform-project-wrapper .phone-sec-wrp.mob-slider-bg {
    transform: scale(1.1);
  }
  .PPplatform-project-wrapper .phone-sec-wrp.mob-slider-bg {
    right: 100px !important;
  }
}

@media (max-width: 992px) {
  .PPplatform-project-wrapper .phone-sec-wrp.mob-slider-bg.f-wrp {
    position: relative !important;
    width: 100% !important;
  }

  .PPplatform-project-wrapper .soc-slider-wrp.f-wrp {
    position: relative !important;
    right: -41px;
    margin: 0 auto;
    float: none;
  }

  .PPplatform-project-wrapper .mob-bg {
    height: 607px;
    width: 776px;
    right: 0;
    margin: 0 auto;
  }

  .PPplatform-project-wrapper .mob-bg img {
    object-position: -69px 2px;
  }

  .PPplatform-project-wrapper .soc-slider-wrp.f-wrp {
    height: 470px;
    width: 223px;
    top: 13px;
  }
}

@media (max-width: 800px) {
  .PPplatform-project-wrapper .response-bg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 254px;
    top: 54px;
  }
  .PPplatform-project-wrapper .soc-slider-wrp.f-wrp{
    right: 0;
  }
  .PPplatform-project-wrapper .mob-bg {
    display: none;
  }
}

@media (max-width: 767px) {
  .PPplatform-project-wrapper .banner-text {
    font-size: 24px;
  }
}


@media (max-width: 468px) {
  .PPplatform-project-wrapper .soc-slider-wrp.f-wrp {
    top: 58px;
  }
}