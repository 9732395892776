@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Red+Hat+Text:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

#root {
  height: 100vh !important;
}

ul,
ol {
  list-style-type: none;
}

.App {
  height: 100% !important;
}

/*===== 
Colors
===== */
:root {
  --transparent: #ffffff68;
  --primary: #4c2aa6;
  --accent: #a667fc;
  --secondary: #542d76;
  --neutral: #aeacac66;
  --modal: #00000066;
  --danger-400: #c8522c;
  --danger: #c23f15;
  --warning-300: #fbedbb;
  --warning-400: #ffdd66;
  --warning: #ff9e01;
  --warning-600: #a07e00;
  --black: #101010;
  --white: #fff;
}

.navbar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 600px) {
  .contact-main-wrapper.f-wrp {
    padding-inline: 0px !important;
  }

  .contact-back-arrow {
    padding: 0 0px !important;
  }
}

.nav-link.active {
  border-bottom: 5px solid #fff;
  margin-bottom: -5px;
  color: var(--primary);
}

.nav-link:hover,
.nav-link:focus {
  color: var(--primary);
}

input.form-control::placeholder {
  font-size: 21px !important;
}

.dropbtn {
  background-color: #3498db;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #393838;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin-top: 1px;
}

.dropdown-content a {
  color: black;
  padding: 10px 50px 12px 10px !important;
  text-decoration: none;
  display: block;
  font-weight: 300 !important;
}

.dropdown-content a:hover {
  background-color: #5b5757;
}

.show {
  display: block;
}

.carousel .slide img {
  width: 100% !important;
  vertical-align: top;
  border: 0;
  height: 500px;
}

.yarl__no_scroll{
  padding: 0 !important;
}
.yarl__toolbar{
  opacity: 0.5;
}
.yarl__no_scroll #root .App{
  overflow: hidden;
}
.yarl__portal{
  z-index: var(--yarl__portal_zindex, 99999999) !important;
}
.yarl__thumbnails_thumbnail {
  width: var(--yarl__thumbnails_thumbnail_width, 80px) !important;
  height: var(--yarl__thumbnails_thumbnail_height, 40px) !important;
  background: var(--yarl__thumbnails_thumbnail_background, transparent) !important;
}
.yarl__container{
  background-color: #00000099 !important;
}
.yarl__thumbnails_container {
  background-color: var(--yarl__thumbnails_container_background_color, var(--yarl__color_backdrop, rgba(0, 0, 0, .8))) !important;
}

.yarl__thumbnails_vignette {
  /* background: rgba(0, 0, 0, .8) !important; */
  --yarl__thumbnails_vignette_size: 0% !important;
}

.yarl__slide_captions_container {
  background: var(--yarl__slide_captions_container_background, rgba(0, 0, 0, 0.5)) !important;
  display: none !important;
}

.yarl__slide_captions_container div {
  text-align: center;
}

.yarl__container.yarl__flex_center {
  flex-direction: column-reverse;
}

.yarl__toolbar {
  position: relative !important;
  width: 100%;
  /* top: 25px !important; */
}

.yarl__carousel {
  height: calc(100% - 50px) !important;
}
.yarl__fullsize img{
  width: auto;
  max-height: 95%;
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.my-light-box .yarl__button.yarl__navigation_prev,
.my-light-box .yarl__button.yarl__navigation_next {
  display: none !important;
}