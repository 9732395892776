.art-view-wrapper {
  background: #101010;
  padding-top: 85px;
}

@media screen and (max-width: 768px) {
  .art-view-wrapper {
    padding-top: 25px;
  }
}

.back-btn-wrp {
  padding: 20px 0;
}

.back-btn-wrp a {
  display: flex;
  align-items: center;
  color: #A667FC;
  font-size: 13px;
  text-decoration: none;
}

.back-btn-wrp img {
  width: 30px;
}

.art-view-wrapper .left-side-wrp {
  padding-right: 30px;
  padding-bottom: 30px;
}

.art-view-wrapper .link-extract-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 768px) {
  .art-view-wrapper .left-side-wrp {
    padding-right: 0px;
  }
}

.art-view-wrapper .left-side-wrp .MagnifySlider .slick-slider {
  /* height: 765px; */
  height: 100%;
}

.art-view-wrapper .left-side-wrp .slider-sec .slick-list {
  /* overflow: hidden; */
  /* height: 100%; */
}

.art-view-wrapper .left-side-wrp .art-details {
  padding: 10px 0;
}

.art-details p {
  display: flex;
  align-items: center;
  margin: 0;
}

.art-details p img {
  width: 20px;
  margin-right: 5px;
}

.artist-block.f-wrp {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0 40px;
}

.artist-block span {
  width: 95px;
}

.artist-block span img {
  border-radius: 7px;
}

.artist-block .artist-details h4 {
  font-size: 18px;
}

.artist-block .artist-details p {
  font-size: 14px;
  margin: 0;
}

.art-view-wrapper .category-slider {
  padding-bottom: 35px;
}

.art-view-wrapper .category-slider .slick-slider {
  padding-left: 0px;
}


.category-slider .chip-btn {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 8px;
  width: max-content;
  background: #520074;
  text-decoration: none;
}

.each-categry-btn {
  padding-right: 20px;
  padding-left: 0;
}

.category-slider .chip-btn img {
  margin-right: 10px;
  width: 25px;
}


.art-tag-btn ul li {
  float: left;
}

.art-tag-btn ul li:not(:last-child) {
  margin-right: 20px;
}

.art-tag-btn .chip-btn {
  padding: 8px 20px;
  background: #8C36FF;
  font-size: 13px;
  display: inline-block;
  width: max-content;
  border-radius: 50px;
  transition: 0.5s all;
  margin-right: 20px;
}

.art-tag-btn .chip-btn:hover,
.art-tag-btn .chip-btn:active {
  background: #fff;
  color: #8C36FF;
  cursor: pointer;
  transition: 0.5s all;
}

.image-detail-wrp {
  padding: 50px 0 20px;
}

.image-detail-wrp h5 {
  font-size: 18px;
  margin-bottom: 15 px;
}

.image-detail-wrp p {
  /* margin-bottom: 0; */
}

.image-detail-wrp p b {
  font-weight: normal;
  color: #AEACAC;
}

.btn-wrap ul {
  display: flex;
  gap: 20px;
}

.btn-wrap .block-btn {
  color: #fff;
  text-decoration: none;
  padding: 12px 20px;
  max-width: 184px;
  display: block;
  min-height: 50px;
  text-align: center;
  border-radius: 5px;
  margin-top: 40px;
  font-weight: 400;
  cursor: pointer;
  min-width: 150px;
}

.btn-wrap .block-btn.initial-btn {
  background: #4C2AA6;
}

.btn-wrap .block-btn.buy-btn {
  background: #8C36FF;
}

.price-detail-sec h3 {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: normal;
}

.price-detail-sec h3 img {
  /* width: 30px;
  margin-right: 10px; */
  width: 15px;
  margin-right: 3px;
  height: 25px;
}

.price-detail-sec h3 b {
  font-size: 12px;
  padding-right: 8px;
  padding-left: 12px;
}



.right-content-sec.f-wrp {
  padding-left: 60px;
}

@media screen and (max-width: 768px) {
  .right-content-sec.f-wrp {
    padding-left: 0px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
}

.profile-card-wrp {
  background: linear-gradient(167deg, #1C0128 30.25%, rgba(22, 2, 30, 0.00) 85.52%);
  padding: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .profile-card-wrp {
    padding: 24px;
  }
}

.low-text {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.content-text {
  max-height: 100px;
  overflow: hidden;
}

.content-text.expanded {
  max-height: 100%;
}

.profile-card-wrp .profile-card-header p i {
  font-style: normal;
  font-size: 12px;
}

.profile-card-con button {
  color: #A667FC;
  text-decoration: underline;
  font-size: 0.85rem;
  padding: 0;
  background: transparent;
  border: none;
}

.art-view-wrapper .profile-card-con p {
  overflow: hidden;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; */
}

.art-gallery-wrp ul {
  column-count: 2;
}

.art-gallery-wrp ul li {
  margin-bottom: 10px;
}

.art-main-wrapper .left-side-wrp .slider-sec .slick-prev::before,
.art-main-wrapper .left-side-wrp .slider-sec .slick-next::before {
  width: 21px;
  height: 33px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.art-main-wrapper .left-side-wrp .slider-sec .slick-prev::before {
  content: '';
  background-image: url(../../../assets/images/icons/prev.svg);
}

.art-main-wrapper .left-side-wrp .slider-sec .slick-next::before {
  content: '';
  background-image: url(../../../assets/images/icons/next.svg);
}

.profile-card-header h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-card-header .share-btn-box {
  display: flex;
  width: auto;
  gap: 15px;
}

.profile-card-header .share-btn-box button {
  padding: 0;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
}


.image-popup-btn{
  display: block;
  width: 100%;
  padding: 0;
  background: transparent;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  height: 100%;
}
.image-popup-btn img{
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}
