.xmedpro-project-wrapper .project-wrapper {
  padding-top: 0;
}

.xmedpro-project-wrapper .project-banner-sec {
  background-image: url('../../../../assets/images/propBg1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  padding-top: 85px;
}

.xmed-abt-wrapper {
  background-image: url('../../../../assets/images/propBg2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
}

.xmed-abt-sec-wrp {
  background-image: url('../../../../assets/images/propBg3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  padding-top: 75px;
}

.xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner {
  min-height: max-content;
  padding: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: space-between;
}

.xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-lft-con {
  /* width: 100%; */
  width: max-content;
  padding-top: 0px;
}

.xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con {
  /* width: 100%; */
  width: max-content;
}



.xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con p {
  font-weight: 300;
  max-width: 420px;
}

.xmedpro-main-slider {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  float: none;
}

.xmedpro-main-slider .xmedpro-slider {
  display: block;
}

.xmedpro-main-slider .xmedpro-slider p {
  display: flex;
  float: right;
  margin-top: 0;
  align-items: center;
  gap: 5px;
  padding-right: 30px;
}

.xmedpro-main-slider .xmedpro-slider {
  /* padding-top: 30px; */
  transition: 1s all ease;
  transform: translate(100px, 0);
  filter: blur(5px);
  transition: 1s all ease;
  height: calc(100% - 65px);
  padding-top: 40px;
}

.xmedpro-main-slider .xmedpro-slider:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.xmedpro-main-slider .xmedpro-slider img {
  border-radius: 15px;
}

.xmedpro-main-slider .slick-slide.slick-active.slick-center.slick-current .xmedpro-slider {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  transition: 1s all ease;
  transform: translate(0px, 0);
  filter: blur(0px);
  height: 100%;
  z-index: 9;
  position: relative;
}

.xmedpro-main-slider .slick-slide.slick-active.slick-center.slick-current+.slick-slide .xmedpro-slider {
  transform: translate(-100px, 0);
  height: calc(100% - 65px);
  padding-top: 40px;
}

.xmed-abt-slider.slick-prev,
.xmed-abt-slider.slick-next,
.xmedpro-main-slider .slick-prev,
.xmedpro-main-slider .slick-next {
  z-index: 999;
}

.xmed-abt-slider .slick-prev .xmedpro-main-slider .slick-prev {
  left: -20px;
}

.xmed-abt-slider .slick-next .xmedpro-main-slider .slick-next {
  right: 20px;
}

.xmedpro-main-slider .slick-prev,
.xmedpro-main-slider .slick-next{
  width: max-content;
  height: max-content;
}

.xmedpro-main-slider .slick-prev:before,
.xmedpro-main-slider .slick-next:before {
  content: '';
  width: 50px;
  height: 50px;
  display: block;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  transition: 0.5s all;
}

.xmed-abt-slider .slick-prev:before,
.xmed-abt-slider .slick-next:before {
  content: '';
  width: 30px;
  height: 30px;
  display: block;

}

.xmed-abt-slider .slick-prev::before,
.xmedpro-main-slider .slick-prev:before {
  content: '';
  background-image: url('../../../../assets/images/carousel/xmedpro/banner/PropLeft.png');
}

.xmed-abt-slider .slick-next:before,
.xmedpro-main-slider .slick-next:before {
  content: '';
  background-image: url('../../../../assets/images/carousel/xmedpro/banner/PropRight.png');
}

.xmed-abt-sec-wrp .xmed-abt-con {
  padding-bottom: 50px;
}

.xmed-abt-sec-wrp .xmed-abt-con .xmed-abt-con-head h1 {
  text-transform: uppercase;
  font-size: 35px;
  max-width: 500px;
}

.xmed-abt-sec-wrp .xmed-abt-con .xmed-abt-con-head p {
  max-width: 600px;
}

.xmed-abt-wrapper .abt-header-wrp {
  text-align: center;
  max-width: 680px;
  margin: 0 auto;
  float: none;
  padding: 75px 0 100px;
}

.xmed-abt-wrapper .abt-header-wrp h1 {
  padding-bottom: 20px;
  font-weight: 700;
}

.xmed-abt-wrapper .abt-header-wrp p {
  font-size: 24px;
  font-weight: 300;
}

.xmedpro-project-wrapper .seeMore-btn-wrp {
  padding: 80px 0 30px;
}

.xmedpro-project-wrapper .seeMore-btn-wrp .seeMore-btn {
  padding: 10px 40px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
  color: #000;
  width: max-content;
}

.xmedpro-project-wrapper .seeMore-btn-wrp .seeMore-btn .icon {
  width: 15px;
  margin-left: 5px;
}






.xmed-abt-sec-wrp .abt-main-box {
  /* background-image: url('../../../../assets/images/xmedpro/xmedMobBG.png');
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-position: center bottom; */
  height: 100%;
}
.xmed-abt-sec-wrp .abt-main-box::before{
  content: '';
  width: 100%;
  height: 80%;
  background-image: url('../../../../assets/images/xmedpro/propMobBG.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 20px;
}

.xmed-abt-sec-wrp .abt-main-box span {
  height: calc(100% + 50px);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.xmed-abt-sec-wrp .abt-main-box span img {
  /* height: 100%; */
  object-fit: contain;
  object-position: top;
}

.xmedpro-project-wrapper .xmed-abt-sec-wrp .each-abt-box.mar-btm30 {
  margin-bottom: 40px;
}

.xmed-abt-sec-wrp .each-abt-box span{
  border-radius: 20px;
  overflow: hidden;
}

.xmed-abt-sec-wrp .each-abt-box.mar-btm15 {
  margin-bottom: 30px;
}

.xmed-abt-sec-wrp .each-abt-box .abt-con {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.02px;
}

.xmed-abt-sec-wrp .each-abt-box .abt-con p {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.02px;
  display: block;
  text-align: center;
  font-size: 20px;
}

.abt-pad-foot {
  background-image: url('../../../../assets/images/xmedpro/footer.png');
  background-size: 100% 80%;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 100%;
}

.xmed-abt-slider .phone-wrp {
  max-width: 850px;
  margin: 0 auto;
  float: none;
}















.project-wrapper {
  background-color: #000;
}

.laptop {
  opacity: 0.9;
  width: 100%;
  height: 80%;
  object-fit: contain;
  object-position: center;
}

.description {
  margin: 0;
  font-size: 20px !important;
  line-height: 1.5;
}

.icon {
  width: 24px;
  display: inline;
  position: relative;
  animation: mapPointpulse 5s infinite;
}

.xmed-abt-slider {
  padding-bottom: 75px;
}

.xmed-abt-slider .each-item-sec.f-wrp {
  height: 565px;
}

.xmed-abt-slider .each-item-sec.f-wrp img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.xmedpro-project-wrapper .soc-slider-wrp .slick-slide .each-item-sec {
  padding-top: 65px;
  transition: 1s all;
  padding-left: 10px;
  padding-right: 10px;
}

.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-active.slick-current .each-item-sec {
  padding-top: 65px;
  transition: 1s all;
}

.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-active.slick-current+.slick-active .each-item-sec {
  padding-top: 0;
  transition: 1s all;
}



/* .xmedpro-project-wrapper .description {
  font-size: 24px !important;
}
.xmedpro-project-wrapper .blkshade::before{
  z-index: 1;
}
.xmedpro-project-wrapper .soc-slider-wrp .slide,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-cloned{
  transition: 1s all;
  transform: scale(0);
}
.xmedpro-project-wrapper .soc-slider-wrp .slide.previous,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-active.slick-current + .slick-active{
  transform: scale(1);
}
.xmedpro-project-wrapper .soc-slider-wrp .slide.selected,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-active,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide.slick-cloned{
  transform: scale(0.8);
}
.xmedpro-project-wrapper .soc-slider-wrp .slick-slider.slick-initialized,
.xmedpro-project-wrapper .each-item-sec,
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide{
  height: 100%;
}

.soc-slider-wrp .slick-slide > div{
  display: block;
  height: 100%;
}
.soc-slider-wrp *:focus-visible{
  outline: -webkit-focus-ring-color auto 0px;
}
.xmedpro-project-wrapper .soc-slider-wrp .slick-slide img{
  width: auto;
  height: 100%;
  object-fit: fill;
  object-position: center;
  margin: 0 auto;

} */
.soc-slider-wrp .slick-slider .slick-track,
.soc-slider-wrp .slick-slider .slick-list {
  height: 100%;
}

@media (max-width: 992px) {
  .phone-sec-wrp {
    position: relative !important;
    width: 100% !important;
    padding-top: 55px;
    height: 100% !important;
  }
  
  .soc-slider-wrp .slick-slider .slick-track,
  .soc-slider-wrp .slick-slider .slick-list {
    height: auto;
  }

  .laptop {
    height: 100%;
  }

  .xmedpro-project-wrapper .blkshade::before {
    width: 35%;
    opacity: 0.5;
  }

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con .chips-container {
    justify-content: center;
  }

  .xmed-abt-sec-wrp .xmed-abt-con .xmed-abt-con-head h1 {
    font-size: 26px;
  }
  

}


@media (min-width: 767px) {

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-lft-con .banner-text {
    font-size: 64px;
  }

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con p {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .xmedpro-project-wrapper .seeMore-btn-wrp .project-switch-wrp ul {
    flex-wrap: wrap;
  }

  .xmedpro-project-wrapper .seeMore-btn-wrp .project-switch-wrp ul li:nth-child(1) {
    order: 2;
  }

  .xmedpro-project-wrapper .seeMore-btn-wrp .project-switch-wrp ul li:nth-child(2) {
    order: 1;
    width: 100%;
    margin-bottom: 30px;
  }

  .xmedpro-project-wrapper .seeMore-btn-wrp .project-switch-wrp ul li:nth-child(3) {
    order: 3;
  }

  .xmed-abt-slider .each-item-sec.f-wrp {
    height: auto;
  }

  .xmed-abt-wrapper .abt-header-wrp {
    padding: 50px 0 0px;
  }

  .xmed-abt-sec-wrp {
    padding-top: 30px;
  }

  .xmed-abt-sec-wrp .xmed-abt-con .xmed-abt-con-head h1 {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .xmed-abt-sec-wrp .xmed-abt-con .xmed-abt-con-head p {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner {
    padding-top: 60px;
    padding-bottom: 60px;
  }



  .xmedpro-main-slider .xmedpro-slider {
    transform: unset;
    padding-top: 00px;
    filter: blur(0px);
    padding: 10px;
  }


  .xmedpro-main-slider .slick-slide.slick-active.slick-center.slick-current .xmedpro-slider {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    transform: unset;
  }

  .xmedpro-main-slider .slick-slide.slick-active.slick-center.slick-current+.slick-slide .xmedpro-slider {
    transform: unset;
    padding-top: 00px;
  }
  
  .xmedpro-main-slider .slick-slider .slick-prev {
    left: 0px;
  }

  .xmedpro-main-slider .slick-slider .slick-next {
    right: 5px;
  }
  .xmedpro-main-slider .slick-prev:before, .xmedpro-main-slider .slick-next:before{
    width: 25px;
    height: 25px;
  }

  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-lft-con {
    width: 100%;
    margin: 0 auto;
  }
  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con {
    width: 100%;
    margin: 0 auto;
  }
  .xmedpro-project-wrapper .xmedpro-banner-con.project-sub-banner .banner-ryt-con p{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .xmed-abt-wrapper .abt-header-wrp p{
    font-size: 14px;
  }














}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .xmed-abt-sec-wrp .xmed-abt-con .row .col-xs-12 {
    padding-bottom: 15px;
  }

  .xmed-abt-sec-wrp .abt-main-box {
    display: none;
  }
}

@media (max-width: 468px) {
  .phone-sec-wrp{
    padding-top: 10px;
  }
}
