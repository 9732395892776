@import url('https://fonts.googleapis.com/css2?family=Khula:wght@600;700&family=Red+Hat+Display:wght@400;500;600;700&family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

::-moz-selection {
    /* Code for Firefox */
    color: #0071b9;
    background: #76d5ff;
}

::selection {
    color: #0071b9;
    background: #76d5ff;
}

html,
body {
    width: 100%;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: #ffffff;
    overflow-x: hidden;
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
a {

    color: #ffffff;
}

p {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

.inner-page-wrapper,
.inner-page-wrapper h1,
.inner-page-wrapper h2,
.inner-page-wrapper h3,
.inner-page-wrapper h4,
.inner-page-wrapper h5,
.inner-page-wrapper h6,
.inner-page-wrapper label,
.inner-page-wrapper span,
.inner-page-wrapper a {

    color: #000000;
}

.inner-page-wrapper p {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

.f-wrp {
    width: 100%;
    display: block;
    float: left;
    position: relative;
}

a:focus,
a:hover {
    text-decoration: none;
}

img,
span {
    width: 100%;
    display: block;
}

ul,
li {
    text-decoration: none;
    list-style: none;
    padding: 0;
}

p {
    font-size: 14px;
}

a:focus,
a:hover {
    color: inherit;
}

.page-main-wrapper.f-wrp>.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.bg-img {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.parlx-bg.bg-img {
    background-attachment: fixed !important;
}

.inner-sec-wrp.parlx-bg {
    display: flex;
    align-items: center;
    padding: 100px 0;
    min-height: 100vh;
}

.inner-sec-wrp.harness-blk {
    background: radial-gradient(89.48% 97.27% at 10.59% 11.69%, #DF5022 0%, #A6028F 100%);
    padding-top: 35px;
    padding-bottom: 35px;
}

.page-main-banner {
    /* background-image: url("../../images/bannerbg.png"); */
    background-image: url("../images/bannerbg.png");
    min-height: 750px !important;
    background-position: top center !important;
}

.teslabot {
    background-image: url("../images/tesla-bg.jpg");
}

.supercar {
    background-image: url("../images/grace-bg.jpg");
}

.shades {
    background-image: url("../images/shades-bg.jpg");
}

.anyhnb {
    background-image: url("../images/anyhnb-bg.jpg");
}

.blkshade {
    position: relative;
}

.blkshade::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/Grace-Riviera.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.no-pading {
    padding: 0 !important;
}

.navbar {
    background: #520074;
    width: 100%;
    --bs-navbar-hover-color: #ffffff;
}

@media screen and (min-width: 991px) {
    .navbar {
        /* background: #520074; */
        position: absolute;
        width: 100%;
        background: transparent;
        box-shadow: none;
        --bs-navbar-hover-color: #ffffff;
    }

    .paddingTop75 {
        padding-top: 75px;
    }
}

.contact-wrp-header.page-main-header.f-wrp .navbar {
    background: #520074;
    position: relative;
    padding: 15px 25px 10px
}

.contact-wrp-header .navbar .hover-wrp {
    padding-bottom: 0;
}

.navbar-brand:focus,
.navbar-brand:hover {
    color: #ffffff;
}

.navbar a,
.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

button.navbar-brand,
a.navbar-brand {
    text-align: right;
    font-family: 'Khula', sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 0.8;
    padding: 0;
    border: none;
    box-shadow: none;
}

button.navbar-brand:focus,
button.navbar-brand:active,
a.navbar-brand:focus,
a.navbar-brand:active {
    box-shadow: 0 0 0 0rem rgba(49, 132, 253, .5);
}

button.navbar-brand img,
a.navbar-brand img {
    width: 60px;
}

.navCloseBtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    /* background-color: #0000001d; */
}

.hover-wrp {
    position: relative;
    display: flex;
    flex-direction: column;
    width: max-content;
    height: 100%;
    float: left;
    padding-bottom: 15px;
}

.PC-dropdown {
    min-width: 225px;
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.5);
    /* border: 0.5px solid #520074; */
    box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 36px 0px rgba(255, 255, 255, 0.5) inset;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: #520074;
    position: absolute;
    left: 0;
    top: 70px;
    display: block;

}

.navbar-dark .navbar-brand {
    display: block;
    height: 60px;
}

.PC-dropdown.close {
    display: block;
    overflow: hidden;
    width: 0;
    max-height: 0px;
    transition: max-height 0.5s;
}

.PC-dropdown.close>ul>li {
    max-height: 0px;
    transition: max-height 0.5s;
    overflow: hidden;
}

.PC-dropdown.open {
    display: block;
    width: 100%;
    max-height: 300px;
    transition: max-height 0.5s;
}

.PC-dropdown.open>ul>li {
    max-height: 50px;
    transition: max-height 0.5s;
    overflow: hidden;
}

.PC-dropdown .popover-arrow {
    display: none;
}

.hover-wrp .navbar-brand.navbar-brand {
    margin: 0;
}

.PC-dropdown ul {
    margin-bottom: 0;
    padding: 15px 0;
    border-radius: 15px;
}

.PC-dropdown ul li {
    margin-bottom: 5px;
}

.PC-dropdown ul li a,
.PC-dropdown ul li span {
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
}

.PC-dropdown ul li a:hover,
.PC-dropdown ul li span:hover {
    backdrop-filter: blur(7px);
    background: rgba(255, 196, 68, 0.8);
    box-shadow: inset 0 4px 36px 0 hsla(0, 0%, 100%, .36), inset 0 4px 4px 0 hsla(0, 0%, 100%, .1), inset 0 1px 1px 0 hsla(0, 0%, 100%, .15);
}



.PC-dropdown ul li.dropDown {
    position: relative;
}

.PC-dropdown ul li .dropDown-wrp {
    padding-left: 15px;
    position: absolute;
    left: 100%;
    top: 0;
    border-radius: 15px;
    overflow: hidden;
    max-height: 0;
}

.dropDownMenu {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: rgba(255, 196, 68, 0.8);
    box-shadow: inset 0 4px 36px 0 hsla(0, 0%, 100%, .36), inset 0 4px 4px 0 hsla(0, 0%, 100%, .1), inset 0 1px 1px 0 hsla(0, 0%, 100%, .15);
    border-radius: 15px;
    width: 235px;
    display: block;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 180px);
}

.dropDownMenu::-webkit-scrollbar {
    width: 3px;
}

.dropDownMenu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.dropDownMenu::-webkit-scrollbar-thumb {
    background-color: #520074;
    outline: 1px solid #520074;
}

.dropDown-wrp::before {
    content: '';
    left: 0px;
    top: 15px;
    width: 15px;
    height: 15px;
    border: 12px solid rgba(255, 196, 68, .68);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 0px;
    display: block;
    position: absolute;
}

.PC-dropdown ul li.dropDown:hover {
    overflow: visible;
}

.PC-dropdown ul li.dropDown:hover .dropDown-wrp {
    max-height: 1000px;
    transition: max-height 0.7s;
}

.PC-dropdown ul li.dropDown:hover .dropDownMenu {
    display: block;
}

/* 
.dropDownMenu a:hover{
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: #520074;
} */







.banner-txt.f-wrp {
    padding-top: 130px;
}

.main-title {
    font-family: 'Khula', sans-serif;

}

.sqr-btn {
    color: #ffffff;
    font-family: 'Red Hat Display', sans-serif;
    border: 1px solid #ffffff;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 12px 60px;
    font-weight: 500;
    font-size: 20px;
}

.banner-txt.f-wrp .sqr-btn {
    margin-top: 45px;
}

.Typewriter__wrapper {
    display: inline;
    width: max-content;
}

.Typewriter__cursor {
    display: inline-block;
    opacity: 0;
    width: 1px;
    animation: cursorAnimation 1s !important;
    animation-duration: 1s !important;
    animation-delay: 0.5s !important;
    animation-iteration-count: 3.4 !important;
}

.main-banner-wrapper-2 .banner-txt .banner-con p b {
    animation: cursorAnimation 1s !important;
    animation-duration: 1s !important;
    animation-delay: 0.5s !important;
    animation-iteration-count: infinite !important;
    opacity: 0;
}

.Typewriter__cursor {
    display: none;
    opacity: 0;
    width: 1px;
    animation: cursorAnimationEnd 2s infinite !important;
    animation-duration: 1s !important;
    animation-delay: 3.6s !important;
}

@keyframes cursorAnimation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes cursorAnimationEnd {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}





.banner-robo-sec.f-wrp {
    width: 270px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 200px;
}

.banner-robo-sec.f-wrp a {
    color: #A667FC;
    width: 200px;
    text-align: center;
    display: block;
    border: 1px solid #A667FC;
    margin-bottom: 15px;
    text-decoration: none;
    padding: 10px;
    border-radius: 50px;
    font-size: 14px;
}

.banner-robo-sec.f-wrp ul li {
    text-align: center;
    text-align: -webkit-center;
    margin: 0 auto;
}

.loction-sec span {
    display: flex;
    align-items: center;
    font-size: 17px;
}

.loction-sec span img {
    width: 17px;
    height: 17px;
    margin-right: 8px;
    position: relative;
    animation: mapPointpulse 5s infinite;
}

@keyframes mapPointpulse {
    0% {
        transform: scale(1) rotateY(0deg);
        top: 0;
    }

    50% {
        transform: scale(1.3) rotateY(360deg);
        top: -5px;
    }

    100% {
        transform: scale(1) rotateY(0deg);
        top: 0;
    }
}


.loction-sec {
    /* position: absolute;
    left: 0;
    bottom: 30px; */
    padding-top: 30px;
}

.project-details-sec.f-wrp h1 {
    font-family: 'Khula', sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1;
    margin: 0;
}

.project-description.f-wrp {
    margin: 25px 0;
}

.project-description.f-wrp p {
    width: 305px;
    font-size: 16px;
    display: none;
}

.inner-sec-wrp.bg-img.parlx-bg.supercar.f-wrp .project-description.f-wrp p {
    text-shadow: 1px 1px #000;
}

.section-content-wrp.f-wrp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* min-height: 750px; */
    justify-content: center;
}

.inner-sec-wrp.bg-img .section-content-wrp.f-wrp {
    max-width: 500px;
}

.inner-sec-wrp.bg-img .section-content-wrp.f-wrp .project-details-sec * {
    color: #000000;
}

.sec-content.f-wrp {
    padding: 45px 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
}

.teslabot .sec-content.f-wrp {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=0);
}

.supercar .sec-content.f-wrp {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

.shades .sec-content.f-wrp,
.anyhnb .sec-content.f-wrp {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}





.section-content-wrp.f-wrp .project-details-sec .pln-btn {
    display: flex;
    align-items: center;
    float: left;
    white-space: nowrap;
    color: #A667FC !important;
    font-weight: 700;
}

.sec-content .pln-btn .longArrow {
    width: 30px;
    display: block;
    margin-left: 8px;
}

.sec-content .pln-btn:hover .longArrow {
    width: 25px;
    overflow: hidden;
}

.sec-content .pln-btn .longArrow svg {
    width: 100%;
    display: block;
    position: relative;
}

.sec-content .pln-btn:hover .longArrow svg {
    animation: arrowMove 1.5s infinite;
}

@keyframes arrowMove {
    0% {
        right: 30px;
    }

    100% {
        right: -30px;
    }

}






.capsule-btn {
    background: #4C2AA6;
    display: inline-block;
    width: auto;
    padding: 10px 35px;
    font-size: 14px;
    border-radius: 65px;
}

.project-label.f-wrp ul li {
    float: left;
}

.project-label.f-wrp ul li:not(:first-child) {
    margin-left: 15px;
}

.project-label.f-wrp {
    margin-bottom: 25px;
}


.blk-txt-wrp .project-details-sec.f-wrp h1,
.blk-txt-wrp h3,
.blk-txt-wrp .sqr-btn,
.blk-txt-wrp .loction-sec span,
.blk-txt-wrp .project-description.f-wrp p,
.foot-link-wrp a,
.foot-link-wrp span,
.footer-content-wrp .main-title,
.blk-txt-wrp h1,
.blk-txt-wrp span,
.blk-txt-wrp p {
    color: #000000;
}

.blk-txt-wrp .sqr-btn {
    border-color: #000000;
}

.blk-txt-wrp .capsule-btn {
    color: #ffffff;
}

.foot-link-wrp .list-inline {
    float: right;
}

.foot-link-wrp a {
    font-family: 'Red Hat Display', sans-serif;
    text-decoration: none;
    font-weight: 700;
}

.footer-content-wrp .main-title {
    font-size: 64px;
    font-weight: 700;
}

.footer-content-wrp.f-wrp {
    display: flex;
    min-height: 500px;
    align-items: center;
}

.footer-sec-wrp {
    padding: 75px 0 25px;
}

.copy-txt-wrp.f-wrp span,
.copy-txt-wrp.f-wrp p {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.copy-txt-wrp.f-wrp p {
    text-align: center;
    margin: 0;
    padding-left: 9%;
}

.copy-txt-wrp.f-wrp span {
    color: #AEACAC;
    text-align: right;
}

.footer-sep .else,
.footer-sep .art {
    display: none;
}

.page-main-wrapper.f-wrp .footer-sep .else {
    display: block;
}

.page-main-wrapper.f-wrp .footer-sep .art {
    display: none;
}

.page-main-wrapper.f-wrp.inside-art-wrp .footer-sep .else {
    display: none;
}

.page-main-wrapper.f-wrp.inside-art-wrp .footer-sep .art {
    display: block;
    padding: 0;
}

.page-main-wrapper.f-wrp.inside-art-wrp {
    background: #101010;
}

.footer-sep .art {
    color: #fff;
}



.inner-page-wrapper.f-wrp {
    padding: 95px 0;
}

.contact-main-wrapper.f-wrp {
    padding: 0 120px;
}

.contact-main-wrapper .main-title {
    font-weight: 700;
}

.contact-main-wrapper form {
    padding-top: 15px;
}

.form-title {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 100;
}

.form-control {
    border-radius: 0;
    border: none;
    font-size: 22px;
    border-bottom: 1px solid #000000;
    height: 75px;
    background: transparent;
    line-height: 0;
}

.form-control.phoneNumberWithIcon .PhoneInputInput {
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 22px;
    height: 75px;
    line-height: 0;
}

.PhoneInput.PhoneInput--focus input {
    outline: -webkit-focus-ring-color auto 0px;
}

.PhoneInputCountrySelect option {
    font-size: 16px;
}

textarea.form-textarea {
    line-height: 1.5;
    border-radius: 0;
    border: none;
    font-size: 22px;
    border-bottom: 1px solid #000000;
    min-height: 75px;
    background: transparent;
    width: 100% !important;
    max-width: 100%;
    min-width: 100%;
    height: 100% !important;
}

textarea.form-textarea:focus-visible {
    outline: none;
}

.form-control:focus {
    color: #000000;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid #000000;
    outline: none;
    box-shadow: none;
}

.form-control::placeholder {
    font-size: 22px;
    font-weight: 100;
    line-height: 0;
    color: #AEACAC;
}

.sqr-btn.btn-primary,
.form-group {
    margin-top: 70px;
    background: #ffffff;
    color: #000;
    border-color: #000000;
    border-radius: 0;
}

.contact-main-wrapper form p {
    color: #8F90A6;
    font-size: 17px;
}

.contact-back-arrow {
    padding: 0 90px;
    font-size: 24px;
    font-weight: 700;
}

.contact-back-arrow .longArrow svg {
    width: 20px;
    height: 20px;
}

.contact-wrapper .inner-page-wrapper.f-wrp {
    padding-top: 60px;
}

.contact-wrapper .inner-page-wrapper.f-wrp .contact-back-arrow {
    margin-bottom: 60px;
}

.contact-back-arrow.back-btn {
    margin-bottom: 0 !important;
}

.contact-back-arrow.back-btn a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.copy-txt-wrp.inner-page-copy.f-wrp p {
    padding-left: 0;
}

.social-list-wrp ul {
    margin: 0;
}

.social-list-wrp li a {
    width: 15px;
    height: 15px;
    display: block;
}









.response-btn {
    text-align: center;
}

.response-btn .sqr-btn {
    color: #000;
    border-color: #000;
    margin: 50px auto;
    display: none;
}

.home-launch-section .img-wrp {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 55%;
    height: 100%;
}

.home-launch-section .img-wrp span,
.home-launch-section .img-wrp span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom left;
}

.home-launch-section .img-wrp span img {
    display: none;
}

.home-launch-section .content-wrp {
    width: 50%;
    float: right;
    min-height: 375px;
    padding-left: 10%;
    display: flex;
    align-items: center;
}

.home-launch-section.f-wrp {
    padding: 10px 0 25px;
}

.home-launch-section .content-wrp h1 {
    font-family: Red Hat Display;
}

.home-launch-section .content-wrp h1:nth-child(1) {
    position: relative;
    left: -10%;
}

.home-launch-section .content-wrp a {
    margin-top: 75px;
    display: inline-block;
    color: #520074;
    font-size: 32px;
    font-weight: 400;
    font-family: Red Hat Display;
}

.home-launch-section .content-wrp a {
    background: rgba(255, 255, 255, 0.65);
    position: relative;
    padding: 15px;
    border-radius: 100px 0px 0px 100px;
    padding-left: 55px;
    padding-right: 30px;
    text-decoration: none;
    color: #000;
    transform: scale(1);
    transition: 0.5s all ease;
}

.home-launch-section .content-wrp a:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 1);
    transition: 0.5s all ease;
}

.home-launch-section .content-wrp a span {
    display: inline-block;
    width: max-content;
    color: #520074;
    /* text-transform: uppercase; */
    padding-left: 1px;
}


.home-launch-section .content-wrp a::after {
    content: '';
    width: 200%;
    height: 100%;
    position: absolute;
    right: calc(-200% + 0px);
    top: 0;
    background: inherit;
}


.strt-proj-sec * {
    font-family: Red Hat Display;
}

.strt-proj-sec.f-wrp {
    display: flex;
    min-height: 275px;
    align-items: center;
}

.strt-proj-sec.f-wrp .row {
    align-items: center;
}

.strt-proj-sec.f-wrp .lft-wrp p {
    font-size: 18px;
    padding-top: 15px;
}

.ryt-wrp .projct-btn.f-wrp {
    margin: 0 auto;
    float: none;
    display: table;
    width: auto;
}

.ryt-wrp .projct-btn.f-wrp .sqr-btn {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    /* transform: perspective(100px) translateZ(0px); */
    transform: scale(1);
    transition: transform 0.5s linear;
}

.ryt-wrp .projct-btn.f-wrp .sqr-btn:hover {
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    /* transform: perspective(100px) translateZ(5px); */
    transform: scale(1.05);
    transition: transform 0.5s linear;
}

.home-main-banner {
    background: #0a0a0a;
    min-height: 100vh;
}

.circle-container {
    position: relative;
    text-align: right;
}

.circle-container li {
    margin-bottom: 35px;
}

.circle-container li span {
    font-family: Red Hat Display;
    font-size: 22px;
    font-weight: 600;
}

.circle-container>*:nth-of-type(1) {
    transform: translate(-14em);
}

.circle-container>*:nth-of-type(2) {
    transform: translate(-15em);
}

.circle-container>*:nth-of-type(3) {
    transform: translate(-14em);
}

.circle-container>*:nth-of-type(4) {
    transform: translate(-12em);
}

.circle-container>*:nth-of-type(5) {
    transform: translate(-9.5em);
}

.circle-container>*:nth-of-type(6) {
    transform: translate(-6em);
}

.circle-container>*:nth-of-type(7) {
    transform: translate(0em);
}

.circle-container>*:nth-of-type(8) {
    transform: translate(0em);
}

.banner-bg-img img,
.banner-bg-img {
    position: absolute;
    top: 0;
    right: 0;
}

.banner-bg-img {
    width: 50%;
    height: 100%;
}

.banner-bg-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
}

.BannerVector {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;
}

.BannerEarth {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 10%;
}

.home-banner-content .banner-txt.f-wrp {
    padding-top: 45px;
    text-align: center;
    margin-bottom: 500px;
    z-index: 99;
    padding-bottom: 100px;
}

.web-consulting-list.pc-list-consult.f-wrp {
    position: absolute;
    right: 45%;
    bottom: 0;
}

span.banner-robo {
    display: table;
    margin: 0 auto;
    width: 185px;
}

.home-banner-content .banner-txt.f-wrp .main-title {
    font-size: 64px;
    white-space: nowrap;
}

.home-banner-content .banner-txt.f-wrp p {
    font-size: 24px;
    white-space: nowrap;
}

.navbar-toggler-icon {
    background-image: url('../images/nav-icon.svg') !important;
}

.dropdown-menu {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: #393838;
    box-shadow: inset 0 4px 36px 0 hsla(0, 0%, 100%, .36), inset 0 4px 4px 0 hsla(0, 0%, 100%, .1), inset 0 1px 1px 0 hsla(0, 0%, 100%, .15);
    border-radius: 15px;
    min-width: 160px;
    overflow: auto;
    background-color: #393838 !important;
}

@media (min-width: 992px) {
    #responsive-navbar-nav .dropdown-menu {
        display: block;
        max-height: 0;
        transition: max-height 0.5s;
        padding-top: 0;
        padding-bottom: 0;
        /* width: 0; */
        overflow: hidden;
        border: none;
    }

    #responsive-navbar-nav .dropdown-menu.show {
        max-height: 800px;
        transition: max-height 0.6s;
        width: auto;
    }

    #responsive-navbar-nav .dropdown-menu a:first-child {
        margin-top: .5rem;
    }

    #responsive-navbar-nav .dropdown-menu a:last-child {
        margin-bottom: .5rem;
    }
}

.dropdown-menu a {
    padding: 10px 40px 10px 20px !important;
    text-decoration: none;
    display: block;
    font-weight: 300 !important;
    font-size: 16px;
}

.dropdown-menu a:hover {
    background-color: #5b5757;
    box-shadow: inset 0 4px 36px 0 hsla(0, 0%, 100%, .36), inset 0 4px 4px 0 hsla(0, 0%, 100%, .1), inset 0 1px 1px 0 hsla(0, 0%, 100%, .15);
    color: #ffffff;
}

.nav-link.active {
    border-bottom: none;
}

.mobile-menu {
    display: none;
}

.mobile-menu a.navbar-brand img,
.LogoImg.mob-logo {
    width: 60px;
}



.page-main-header.f-wrp {
    z-index: 9999999;
}

.page-main-header.f-wrp .container-fluid {
    max-width: 1640px;
}

.inside-header-wrp.page-main-header.f-wrp {
    position: absolute;
    left: 0;
    top: 0;
}

.inside-header-wrp.page-main-header.f-wrp .navbar {
    background: transparent;
    box-shadow: unset;
}

.inside-header-wrp.page-main-header.f-wrp .justify-content-end.navbar-collapse {
    justify-content: center !important;
}

.inside-header-wrp.page-main-header.f-wrp .justify-content-end.navbar-collapse .navbar-nav {
    position: relative;
    left: 0px;
}

.main-banner-wrapper-2 .banner-txt .banner-con {
    min-height: 131px;
}

.main-banner-wrapper-2.f-wrp {
    max-width: 1640px;
    margin: 0 auto;
    display: flex;
    float: none;
    min-height: 100vh;
}

.main-banner-wrapper-2.f-wrp span.banner-robo {
    display: block;
    margin: unset;
    width: 185px;
    height: 185px;
}

.main-banner-wrapper-2.f-wrp span.banner-robo {
    position: relative;
}

.main-banner-wrapper-2.f-wrp span.banner-robo::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/roboThumb.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #0a0a0a;
    opacity: 1;
}

.main-banner-wrapper-2.f-wrp span.banner-robo::after {
    animation: roboBlur 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

.main-banner-wrapper-2.f-wrp span.banner-robo img {
    /* transform: translateY(100vh); */
    /* transform: translateY(90px) translateX(-80px); */
    /* animation: roboAnimate 3s; */
    /* animation: roboTextAnimate 2.5s; */
    animation-delay: 0.2s !important;
    animation-fill-mode: forwards;
    /* animation-iteration-count: infinite !important; */
}

@keyframes roboBlur {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

@keyframes roboAnimate {
    0% {
        transform: translateY(100vh);
    }

    65% {
        transform: translateY(-5vh);
    }

    100% {
        transform: translateY(0vh);
    }

}

.main-banner-wrapper-2.f-wrp .home-banner-content .banner-txt.f-wrp {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

.main-banner-wrapper-2.f-wrp .home-banner-content .banner-txt.f-wrp p {
    margin: 0;
}

.main-banner-wrapper-2 .row {
    height: 100%;
    min-height: 500px;
}

.main-banner-wrapper-2 .row .col-lg-5 {
    display: flex;
    align-items: center;
}

.main-banner-wrapper-2 .row .col-lg-7 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}


.main-banner-wrapper-2 .banner-txt.f-wrp {
    padding-top: 0px;
    padding-left: 55px;
    padding-bottom: 0px;
}

.main-banner-wrapper-2 .banner-txt .banner-con h1 {
    color: #fff;
    font-family: Inter;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.main-banner-wrapper-2 .banner-txt .banner-con p {
    color: #FFF;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 1.3px;
    padding-top: 15px;
}


.main-banner-wrapper-2 .banner-globe-bg {
    height: 100vh;
    width: max-content;
    float: right;
    min-height: 500px;
}

.main-banner-wrapper-2 .banner-globe-bg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right bottom;
}


.web-consulting-list-2 {
    z-index: 2;
    width: fit-content;
    float: right;
    height: 100%;
}

.web-consulting-list-2 .banner-txt {
    display: flex;
    align-items: center;
    /* padding-right: 215px; */
    justify-content: flex-end;
    transform: translate(-36em);
    padding-top: 58px;
    width: max-content;
    float: right;
    padding-left: 155px;
}

.web-consulting-list-2 .banner-txt span.banner-robo {
    display: block;
    margin: unset;
    position: absolute;
    left: -40px;
    top: 0;
}

.web-consulting-list-2 .banner-txt .banner-con {
    text-align: right;
}

.web-consulting-list-2 .banner-txt .banner-con .main-title {
    font-family: "Inter", sans-serif;
    font-size: 5rem;
    margin: 0;
    letter-spacing: -4px;
    font-weight: 700;
}

.web-consulting-list-2 .banner-txt .banner-con p {
    font-size: 20px;
}

.web-consulting-list-2 .circle-container {
    text-align: right;
    position: absolute;
    right: calc(100% + 1.5vw);
    width: max-content;
    bottom: 0;
    /* min-height: 315px; */
}

.main-banner-wrapper-2 .circle-container li {
    float: right;
    clear: both;
}

.main-banner-wrapper-2 .circle-container li>span {
    width: max-content;
    min-width: calc(100% + 5px);
    /* border: 1px solid #fff; */
}

.main-banner-wrapper-2 .circle-container li span .Typewriter {
    display: inline;
    position: absolute;
    left: 0;
    top: 0;
    /* width: 100%; */
    /* white-space: nowrap; */
}

.main-banner-wrapper-2 .circle-container li span p {
    font-size: inherit;
    font-weight: inherit;
    opacity: 0;
}

.main-banner-wrapper-2 .circle-container li {
    margin-bottom: 6.6vh;
}

.main-banner-wrapper-2 .circle-container>*:nth-of-type(1) {
    transform: translate(-2.5vw);
}

.main-banner-wrapper-2 .circle-container>*:nth-of-type(2) {
    transform: translate(-2.2vw);
}

.main-banner-wrapper-2 .circle-container>*:nth-of-type(3) {
    transform: translate(-1vw);
}

.main-banner-wrapper-2 .circle-container>*:nth-of-type(4) {
    transform: translate(1.7vw);
}

.main-banner-wrapper-2 .circle-container>*:nth-of-type(5) {
    transform: translate(6.5vw);
}

.ryt-wrp .projct-btn.f-wrp {
    z-index: 3;
}


.all-proj.f-wrp {
    background: #0A0A0A;
    background: #000000;
    padding: 120px 0;
    text-align: center;
    background-image: url('../images/seeMoreBGTop.png'), url('../images/seeMoreBGBottom.png');
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto 65%, 35% auto;
}

@media screen and (max-width: 991px) {
    .all-proj.f-wrp {
        background-size: auto 50%, 70% auto;
    }
}

.all-proj.f-wrp h1 {
    font-family: "Inter", sans-serif;
    color: #fff;
}

.all-proj.f-wrp .pln-btn {
    position: relative;
    border-radius: 50px;
    /* background: linear-gradient(90deg, #ED4E79 33.38%, #4A137C 100%); */
    box-shadow: inset 0 4px 36px 0 hsla(0, 0%, 100%, .36);
    padding: 12px 85px;
    text-decoration: none;
    margin-top: 0px;
    display: inline-block;
    letter-spacing: 2px;
    font-size: 20px;
    transition: transform 0.5s ease;
    overflow: hidden;
    z-index: 1;
}

@media screen and (max-width: 500px) {
    .all-proj.f-wrp .pln-btn {
        padding: 10px 50px;
        font-size: 16px;
    }
}

.all-proj.f-wrp .pln-btn:hover {
    transform: scale(1.025);
    transition: transform 0.5s ease;
}

.all-proj.f-wrp .pln-btn::after {
    content: '';
    background: linear-gradient(90deg, #ED4E79 33.38%, #4A137C 100%);
    position: absolute;
    left: -10px;
    top: -150px;
    width: 300px;
    height: 300px;
    transform: rotate(0deg);
    z-index: -1;
    transition: transform 0.9s ease;
}

.all-proj.f-wrp .pln-btn:hover:after {
    transform: rotate(360deg);
    transition: transform 0.9s ease;
}

/* .main-banner-wrapper-2 .circle-container li{
    transition: 0.5s all;
} */
/* .main-banner-wrapper-2 .circle-container li span{
    transform: translateX(100vw);
} */
/* .letAnimate .main-banner-wrapper-2 .circle-container>*:nth-of-type(1) span{
    animation: bannerListAnimate 1s;
    animation-delay: 1s !important;
    animation-fill-mode: forwards;
}
.letAnimate .main-banner-wrapper-2 .circle-container>*:nth-of-type(2) span{
    animation: bannerListAnimate 1s;
    animation-delay: 1.4s !important;
    animation-fill-mode: forwards;
}
.letAnimate .main-banner-wrapper-2 .circle-container>*:nth-of-type(3) span{
    animation: bannerListAnimate 1s;
    animation-delay: 1.8s !important;
    animation-fill-mode: forwards;
}
.letAnimate .main-banner-wrapper-2 .circle-container>*:nth-of-type(4) span{
    animation: bannerListAnimate 1s;
    animation-delay: 2.2s !important;
    animation-fill-mode: forwards;
}
.letAnimate .main-banner-wrapper-2 .circle-container>*:nth-of-type(5) span{
    animation: bannerListAnimate 1s;
    animation-delay: 2.6s !important;
    animation-fill-mode: forwards;
} */
/* 
@keyframes bannerListAnimate {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(0vw);
    }

} */


.pageLoader {
    display: flex;
    float: left;
    width: 100%;
    min-height: calc(100vh - 155px);
    justify-content: center;
    align-items: center;
}


.whatsapp-btn {
    padding: 0px;
    min-width: 20px;
    min-height: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    background: transparent;
    border: none;
    position: fixed;
    right: 30px;
    bottom: 50px;
    /* animation: pulse-animation 2s infinite; */
    z-index: 9999;
    transform: scale(1);
    transition: 1s all ease;
}

.whatsapp-btn img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.whatsapp-btn:hover {
    animation: pulse-animation 1.5s infinite;
    cursor: pointer;
    transform: scale(1.05);
    transition: 1s all ease;
}


@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    }
}


@media screen and (max-width: 1315px) {
    .web-consulting-list-2 .banner-txt .banner-con .main-title {
        font-size: 4.1rem;
    }
}

@media screen and (max-width: 1270px) {


    .web-consulting-list-2 .banner-txt {
        transform: translate(-30em);
    }
}

@media screen and (min-width: 767px) {
    .mobile-wrapper {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    /* .main-banner-wrapper.f-wrp {
        display: none;
    } */

    /* .main-banner-wrapper-2.f-wrp {
        display: none;
    } */

}

@media screen and (min-width: 1199px) {
    .main-banner-wrapper.f-wrp {
        display: none;
    }

    .main-banner-wrapper-2.f-wrp {
        display: flex;
    }

    .paddingTop-45px {
        padding-top: 45px;
    }

    .paddingTop-100px {
        padding-top: 100px !important;
    }
}




@media (min-width: 1501px) {
    .page-main-banner {
        background-size: 1500px !important;
        background-color: #0a0a0a;
    }
}

@media screen and (min-width: 992px) {
    .navbar-nav>* {
        margin-left: 30px;
    }

    .dropdown-menu {
        margin-top: -1px !important;
    }

    .dropdown-toggle::after {
        display: none;
    }
}

@media screen and (min-width: 991px) {
    .page-main-banner .banner-txt.f-wrp .main-title {
        font-size: 3rem;
    }

    .page-main-banner .banner-txt.f-wrp span {
        font-size: 1.5rem;
    }

    .web-consulting-list.pc-list-consult.f-wrp {
        right: 260px;
    }
}

@media screen and (max-width: 1200px) {
    .home-launch-section .content-wrp {
        padding-left: 0%;
    }
}

@media screen and (max-width: 992px) {
    .home-banner-content .banner-txt.f-wrp .main-title {
        font-size: 3rem;
    }

    .home-banner-content .banner-txt.f-wrp p {
        font-size: 1.5rem;
    }

    .home-launch-section .content-wrp {
        min-height: auto;
        padding-bottom: 45px;
        width: 75%;
        padding-left: 0;
    }

    .page-main-header.f-wrp nav {
        padding: 12px 15px;
    }

    .mobile-menu {
        display: flex;
    }

    .pc-menu {
        display: none;
    }

    .mobile-menu .container-fluid {
        padding: 0;
    }

    .navhead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 15px;
        background: #520074;
    }

    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #333;
        z-index: 99999;
    }

    .navbar-nav {
        background: #fff;
        width: 100%;
        padding: 0px 0px !important;
        height: calc(100vh - 81px);
        overflow-y: scroll;
        padding-bottom: 50px !important;
    }

    .navbar-nav::-webkit-scrollbar {
        width: 3px;
    }

    .navbar-nav::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    }

    .navbar-nav::-webkit-scrollbar-thumb {
        background-color: #a9a9a9;
        outline: 1px solid #708090;
    }

    .navbar a,
    .navbar-dark .navbar-nav .nav-link {
        color: #000;
    }

    .navbar-nav a {
        padding-left: 40px !important;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 15px !important;
        text-transform: uppercase;
        font-size: 14px !important;
        font-weight: 700 !important;
    }

    .dropdown-menu {
        background: #ffffff;

    }

    .dropdown-toggle+.dropdown-menu {
        background: #FAFAFA !important;
        border-radius: 0;
        box-shadow: none;
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 40px;
    }

    .dropdown-menu a {
        color: #000;
    }

    .dropdown-menu a:not(:last-child) {
        border-bottom: 1px solid #E5E7E8;
    }

    .dropdown-toggle+.dropdown-menu a {
        padding-left: 0 !important;
        font-weight: 500 !important;
        font-size: 13px !important;
    }

    .dropdown-toggle+.dropdown-menu a:hover {
        background-color: #FAFAFA;
    }

    .navbar a:hover,
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .show>.nav-link {
        color: #4C2AA6;
    }

    .dropdown-toggle::after {
        float: right;
        margin-right: 3px;
        margin-top: 10px;
    }

    .navhead .navbar-toggler-icon {
        background-image: url('../images/close-icon.svg') !important;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .home-banner-content .banner-txt.f-wrp {
        padding-top: 45px;
        text-align: center;
        margin-bottom: 385px;
        z-index: 99;
        padding-bottom: 100px;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 100%;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img>span {
        width: 375px;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img span img {
        position: relative;
    }

    .mobile-wrapper .web-consulting-list.pc-list-consult.f-wrp {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: calc(100% - 375px);
    }

    .mobile-wrapper .circle-container {
        position: relative;
        text-align: right;
        padding-right: 30px;
    }

    .web-consulting-list.pc-list-consult.f-wrp {
        position: relative;
        right: 0;
        bottom: 0;
    }

    .circle-container li {
        margin-bottom: 30px;
    }

    .circle-container>*:nth-of-type(1) {
        transform: translate(0px);
    }

    .circle-container>*:nth-of-type(2) {
        transform: translate(20px);
    }

    .circle-container>*:nth-of-type(3) {
        transform: translate(50px);
    }

    .circle-container>*:nth-of-type(4) {
        transform: translate(100px);
    }

    .circle-container>*:nth-of-type(5) {
        transform: translate(160px);
    }

    .mobile-wrapper .container {
        max-width: 100%;
    }

    .home-banner-content .banner-txt.f-wrp {
        padding-top: 75px;
        text-align: center;
        padding-left: 20px;
        margin-bottom: 350px;
        z-index: 99;
        padding-bottom: 100px;
        /* width: 290px; */
        min-height: calc(100vh - 316px);
    }

    .home-banner-content .banner-txt.f-wrp .banner-robo {
        /* margin: unset; */
        width: 145px;
    }

    /* .home-banner-content .banner-txt.f-wrp .main-title{
        
    } */
    .home-banner-content .banner-txt.f-wrp p {
        white-space: normal;
    }

    .main-banner-wrapper.f-wrp {
        max-height: 1000px;
    }

}

@media screen and (max-width: 767px) {

    .home-launch-section .content-wrp {
        width: 100%;
        text-align: right;
    }

    .home-launch-section.f-wrp {
        margin-top: 100px;
    }

    .ryt-wrp {
        margin-top: 35px;
    }

    .home-launch-section .img-wrp {
        width: 85%;
    }

    .inner-sec-wrp.parlx-bg {
        background-position: right !important
    }

    .inner-sec-wrp.parlx-bg.teslabot {
        background-position: center !important;
    }

}

@media screen and (max-width: 665px) {
    .main-banner-wrapper.mobile-wrapper {
        display: flex;
        flex-direction: column-reverse;
        height: 100vh;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img {
        position: relative;
    }

    .home-banner-content .banner-txt.f-wrp {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0;
        padding-bottom: 0;
        min-height: auto;
    }


    .main-banner-wrapper.f-wrp .banner-bg-img {
        padding-top: 0;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img>span {
        width: 235px;
    }

    .mobile-wrapper .web-consulting-list.pc-list-consult.f-wrp {
        width: calc(100% - 235px);
    }

    .mobile-wrapper .circle-container {
        padding-right: 0;
    }

    .circle-container li {
        margin-bottom: 40px;
    }

    .circle-container>*:nth-of-type(1) {
        transform: translate(10px);
    }

    .circle-container>*:nth-of-type(2) {
        transform: translate(-10px);
    }

    .circle-container>*:nth-of-type(3) {
        transform: translate(-20px);
    }

    .circle-container>*:nth-of-type(4) {
        transform: translate(0px);
    }

    .circle-container>*:nth-of-type(5) {
        transform: translate(40px);
    }

    .inner-sec-wrp.f-wrp.paddingTop-100px {
        padding-top: 50px;
    }

    .home-launch-section .img-wrp span,
    .home-launch-section .img-wrp span img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: -130px 75px;
    }

    .home-launch-section .content-wrp a {
        margin-top: 130px;
        font-size: 24px;
    }

    .home-launch-section .content-wrp a::after {
        display: none;
    }

    .home-launch-section .img-wrp {
        width: 340px;
    }

}


@media screen and (min-width: 665px) {
    .home-launch-section .img-wrp span img.pcImg {
        display: block;
    }

    .home-launch-section .img-wrp span img.mobImg {
        display: none;
    }
}

@media screen and (max-width: 665px) {
    .home-launch-section .img-wrp span img.pcImg {
        display: none;
    }

    .home-launch-section .img-wrp span img.mobImg {
        display: block;
    }
}



@media screen and (max-width: 665px) and (min-width: 576px) {
    .home-banner-content .banner-txt.f-wrp {
        position: absolute;
    }
}




@media screen and (max-width: 576px) {
    .home-banner-content .banner-txt.f-wrp .main-title {
        font-size: 2.3rem;
    }

    .home-banner-content .banner-txt.f-wrp p {
        font-size: 1rem;
    }

    .banner-bg-img {
        width: 65%;
        height: 550px;
        top: unset;
        bottom: 0;
    }

    /* .web-consulting-list.pc-list-consult.f-wrp {
        position: absolute;
        right: calc(25% - 10px);
        bottom: 0;
    } */

    .banner-bg-img img,
    .banner-bg-img {
        top: unset;
        bottom: 0;
    }

    .circle-container li {
        margin-bottom: 40px;
    }

    .BannerVector,
    .BannerEarth {
        object-position: right bottom;
    }

    .BannerEarth {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 10%;
    }

    .home-banner-content .banner-txt.f-wrp {
        /* margin-bottom: 110px; */
        padding-bottom: 0px;
    }

    .circle-container li span {
        font-size: 18px;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img span img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left bottom;
    }

    .mobile-wrapper .web-consulting-list.pc-list-consult.f-wrp {
        width: auto;
        position: relative;
    }

    .main-banner-wrapper.mobile-wrapper {
        justify-content: space-between;
    }

    .home-banner-content .banner-txt.f-wrp {
        padding-top: 90px;
    }

    .strt-proj-sec.f-wrp {
        padding-top: 50px;
    }

    .capsule-btn {
        padding: 8px 20px;
        font-size: 12px;
    }

    .project-details-sec.f-wrp h1 {
        font-size: 38px;
    }

    .sec-content.f-wrp {
        padding: 30px 20px;
    }

    .mobile-wrapper .circle-container {
        padding-right: 0;
        margin-bottom: 0;
        height: auto;
    }
}

@media screen and (max-width: 575px) {
    .circle-container>*:nth-of-type(1) {
        transform: translate(-10px);
    }

    .circle-container>*:nth-of-type(2) {
        transform: translate(-20px);
    }
}



@media screen and (min-width: 991px) and (max-width: 1400px) {
    .page-main-banner .banner-txt.f-wrp .main-title {
        font-size: 2.6rem;
    }

    .page-main-banner .banner-txt.f-wrp span {
        font-size: 1.12rem;
    }
}

@media (max-width: 992px) {
    .banner-txt.f-wrp .sqr-btn {
        display: none;
    }

    .response-btn .sqr-btn {
        display: inline-block;
    }

    .page-main-banner.bg-img.f-wrp {
        min-height: 825px !important;
        background-position: bottom center !important;
        background-size: 1165px 90% !important;
        background-color: #0a0a0a;
    }

    .banner-txt.f-wrp {
        padding-top: 35px;
        text-align: center;
    }

    .banner-robo-sec.f-wrp {
        top: 260px;
        width: 200px;
    }

    .banner-robo-sec.f-wrp a {
        width: 160px;
    }

    .blkshade::before {
        background-size: 165% 100%;
    }

    .navbar-toggler {
        background-color: transparent;
        border: none;
    }

    .navbar-nav {
        padding: 10px 0;
    }

    .copy-txt-wrp.f-wrp .row {
        justify-content: center;
    }

    .copy-txt-wrp.f-wrp span {
        text-align: center;
    }

    .copy-txt-wrp.f-wrp p {
        padding-left: 0;
    }

    .nav-link.active {
        border-bottom: none;
    }
}

@media (max-width: 767px) {}

@media (max-width: 600px) {
    button.navbar-brand {
        font-size: 26px;
    }

    .foot-link-wrp.f-wrp {
        display: none;
    }

    .footer-content-wrp.f-wrp {
        min-height: 400px;
        text-align: center;
    }

    .copy-txt-wrp.f-wrp span {
        text-align: center;
    }

    .text-dark.align-items-center.justify-content-center {
        text-align: center;
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media (min-width: 576px) {
    .page-main-header.f-wrp nav {
        padding: 25px 35px 10px;
    }

    .navbar-expand-sm .navbar-nav {
        justify-content: flex-end;
        width: 100%;
    }

    .navbar-expand-sm .navbar-nav li:not(:first-child) {
        margin-left: 30px;
    }

    .main-banner-wrapper.f-wrp .banner-bg-img>span {
        width: 100%;
    }
}

@media (max-width: 450px) {

    .main-banner-wrapper.f-wrp .banner-bg-img>span {
        width: 100%;
        height: calc(100vh - 300px);
        padding-top: 20px;
        /* height: calc(100%);
        padding-top: 0; */
    }

    .mobile-wrapper .web-consulting-list.pc-list-consult.f-wrp {
        width: calc(100%);
        padding-bottom: 10%;
        height: calc(100vh - 300px);
        align-items: flex-end;
        /* width: calc(100%);
        height: calc(100%);
        align-items: flex-end; */
    }

    .home-banner-content .banner-txt.f-wrp .banner-robo {
        width: 115px;
    }

    .circle-container li span {
        font-size: 16px;
    }

}

@media (max-width: 400px) {
    .footer-content-wrp .main-title {
        font-size: 46px;
    }
}

@media (max-width: 325px) {

    .circle-container>*:nth-of-type(1) {
        transform: translate(35px);
    }

    .circle-container>*:nth-of-type(2) {
        transform: translate(10px);
    }

    .circle-container>*:nth-of-type(3) {
        transform: translate(-15px);
    }

    .circle-container>*:nth-of-type(4) {
        transform: translate(-5px);
    }

    .circle-container>*:nth-of-type(5) {
        transform: translate(20px);
    }

    .main-banner-wrapper.f-wrp .banner-bg-img>span {
        width: 100%;
    }

    .mobile-wrapper .web-consulting-list.pc-list-consult.f-wrp {
        width: calc(100% - 125px);
    }

    .circle-container li {
        margin-bottom: 20px;
    }
}

.pc-earth,
.mob-earth {
    display: none;
}

@media screen and (min-width: 767px) {
    .pc-earth {
        display: block;
    }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
    .page-main-header.f-wrp nav {
        padding: 20px 15px 10px;
    }

    .main-banner-wrapper-2 .banner-txt.f-wrp {
        padding-left: 15px;
    }

    .main-banner-wrapper-2.f-wrp span.banner-robo {
        width: 150px;
        height: 150px;
    }

    .banner-txt.f-wrp {
        text-align: left;
    }

    .main-banner-wrapper-2 .banner-txt .banner-con h1 {
        font-size: 40px;
    }

    .main-banner-wrapper-2 .banner-txt .banner-con p {
        font-size: 20px;
    }

    .web-consulting-list-2 {
        width: 50%;
    }

    .main-banner-wrapper-2 .banner-globe-bg {
        width: 100%;
    }

    .main-banner-wrapper-2 .banner-globe-bg img {
        object-fit: cover;
        object-position: left bottom;
    }

    .circle-container li span {
        font-size: 18px;
    }

    .web-consulting-list-2 .circle-container {
        /* right: calc(100% + 0.5vw); */
        right: calc(95%);
    }

    .main-banner-wrapper-2 .circle-container>*:nth-of-type(4) {
        transform: translate(3.7vw);
    }

    .main-banner-wrapper-2 .circle-container>*:nth-of-type(5) {
        transform: translate(10.5vw);
    }
}

@media screen and (max-width: 767px) {
    .main-banner-wrapper.mobile-wrapper {
        display: none;
    }

    .mob-earth {
        display: block;
    }

    .main-banner-wrapper-2 .banner-txt.f-wrp {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 55px;
    }

    .main-banner-wrapper-2.f-wrp span.banner-robo {
        width: 115px;
        height: 115px;
        margin: 0 auto;
    }

    .main-banner-wrapper-2 .banner-globe-bg img {
        object-fit: contain;
        object-position: left bottom;
        position: absolute;
        height: 110%;
        left: 0;
        bottom: 0;
        padding-left: 20px;
        width: 445px;
        overflow: visible;
    }




    .main-banner-wrapper-2 .banner-txt .banner-con {
        position: relative;
        z-index: 9;
    }

    .main-banner-wrapper-2 .banner-txt .banner-con h1 {
        font-size: 2.3rem;
    }

    .main-banner-wrapper-2 .banner-txt .banner-con p {
        font-size: 1rem;
    }

    .circle-container li {
        margin-bottom: 40px;
    }

    /* .main-banner-wrapper-2 .banner-globe-bg{
        position: absolute;
        right: 0;
        top: 0;
        height: 100% !important;
        width: 40%;
        min-height: auto !important;
        display: none;
    } */
    .web-consulting-list-2.pc-list-consult {
        display: flex;
        flex-direction: row-reverse;
    }

    .main-banner-wrapper-2 .banner-globe-bg {
        position: relative;
        width: 445px;
        min-height: 100%;
        height: auto !important;
    }

    .web-consulting-list-2 .circle-container {
        position: relative;
        right: 0;
        width: 100%;
        padding-top: 165px;
        padding-right: 0%;
        padding-left: 0;
    }

    .circle-container li span {
        font-size: 18px;
    }

    .web-consulting-list-2 {
        width: 100%;
    }

    /* .main-banner-wrapper-2 .circle-container{
        background-image: url('../images/Planet.png');
        background-size: auto 100%;
        background-position: right bottom;
        background-repeat: no-repeat;
    } */
    .main-banner-wrapper-2 .circle-container li {
        transform: none !important;
        position: relative;
    }

    .circle-container>*:nth-of-type(1) {
        right: -5px;
    }

    .circle-container>*:nth-of-type(2) {
        right: 0px;
    }

    .circle-container>*:nth-of-type(3) {
        right: -10px;
    }

    .circle-container>*:nth-of-type(4) {
        right: -40px;
    }

    .circle-container>*:nth-of-type(5) {
        right: -100px;
    }
}

@media screen and (max-width: 500px) {
    .circle-container li span {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {
    .main-banner-wrapper-2 .banner-globe-bg {
        width: 290px;
    }

    .circle-container li span {
        font-size: 14px;
    }
}

@media screen and (max-width: 350px) {
    .main-banner-wrapper-2 .banner-globe-bg {
        width: 240px;
    }
}

@media screen and (max-width: 300px) {
    .main-banner-wrapper-2 .banner-globe-bg {
        width: 70%;
    }
}

@media screen and (min-height: 1000px) {
    .main-banner-wrapper-2.f-wrp {
        min-height: 900px;
    }

    .main-banner-wrapper-2 .banner-globe-bg {
        height: 900px !important;
    }

    .main-banner-wrapper-2 .circle-container li {
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 991px) and (min-height: 1000px) {
    .main-banner-wrapper-2 .row {
        flex-direction: column;
    }

    .main-banner-wrapper-2 .row .col-lg-5 {
        width: 100%;
    }

    .main-banner-wrapper-2 .row .col-lg-7 {
        width: 100%;
    }

    .main-banner-wrapper-2 .banner-txt.f-wrp {
        width: max-content;
        margin: 0 auto;
        text-align: center;
    }

    .main-banner-wrapper-2.f-wrp span.banner-robo {
        margin: 0 auto;
    }

    .main-banner-wrapper-2 .banner-globe-bg {
        max-height: 675px !important;
    }

    .main-banner-wrapper-2 .banner-globe-bg img {
        object-fit: contain;
        object-position: center;
    }

}

@media screen and (max-width: 275px) {
    .main-banner-wrapper-2 .circle-container li>span {
        min-width: calc(100% + 0px);
    }

    .web-consulting-list-2 .circle-container {
        width: 47%;
    }
}

@media screen and (max-width: 500px) {
    .web-consulting-list-2 .circle-container {
        padding-top: 100px;
        padding-bottom: 65px;
    }

    .web-consulting-list-2 .circle-container li:nth-of-type(1) {
        right: -30px;
    }

    .web-consulting-list-2 .circle-container li:nth-of-type(2) {
        right: -10px;
    }

    .web-consulting-list-2 .circle-container li:nth-of-type(3) {
        right: -5px;
    }

    .web-consulting-list-2 .circle-container li:nth-of-type(4) {
        right: -20px;
    }

    .web-consulting-list-2 .circle-container li:nth-of-type(5) {
        right: -45px;
    }
}