.tadas-main-wrp .banner-main-wrapper {
  background-image: url('../../../../assets/images/tandas/bannerBG.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 85px;
}

.tadas-main-wrp .banner-main-wrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(53, 10, 71, 0.9) 0%, #101010 100%);
  background-color: #101010;
}


.tadas-main-wrp .banner-main-wrapper .banner-con-box .row {
  align-items: center;
}

.tadas-main-wrp .banner-main-wrapper .banner-con-box .banner__title {
  position: relative;
  top: 0;
  padding-top: 65px;
  padding-bottom: 55px;
}

.tadas-main-wrp .banner-main-wrapper .banner-con-box .banner__title .chips-container .chips{
  background: transparent;
  border: 1.5px solid #A667FC;
  color: #A667FC;
}


.tadas-main-wrp .banner-main-wrapper .banner-con-box .baner-image {
  height: 100%;
}

.tadas-main-wrp .banner-main-wrapper .banner-con-box .baner-image span {
  height: calc(100vh - 110px);
  min-height: 300px;
}

.tadas-main-wrp .banner-main-wrapper .banner-con-box .baner-image img {
  height: 100%;
  object-fit: contain;
  object-position: center;  
}

.tadas-main-wrp .project-switch-wrp {
  padding: 25px 0;
}

.tadas-main-wrp .project-switch-wrp label{
  color: #fff;
}

.tadas-main-wrp .abt-main-wrapper{
  min-height: 100vh;
  padding: 60px 0 80px;
}

.tadas-main-wrp .abt-main-wrapper .abt-content-box{
  max-width: 475px;
  padding-top: 70px;
}

.tadas-main-wrp .abt-main-wrapper .abt-content-box h1 {
  color: #FFE08F;
  font-family: "Red Hat Text";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 32px;
}

.tadas-main-wrp .abt-main-wrapper .abt-content-box p {
  color: #FAFAFC;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box {
  padding-right: 85px;
}
.tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box span{
  height: calc(100vh - 140px);
}
.tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box span img{
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.tadas-main-wrp .challenge-main-wrapper {
  min-height: 100%;
  padding: 60px 0 80px;
}

.tadas-main-wrp .challenge-main-wrapper .challenge-content-box h1 {
  color: #4C2AA6;
  font-family: "Red Hat Text";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 32px;
}

.tadas-main-wrp .challenge-main-wrapper .challenge-content-box p {
  color: #101010;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tadas-main-wrp .challenge-main-wrapper .challenge-content-box{
  max-width: 475px;
  padding-top: 35px;
}
.tadas-main-wrp .challenge-main-wrapper .challenge-con-wrp .challenge-img-box {
  padding-left: 85px;
}
.tadas-main-wrp .challenge-main-wrapper .challenge-con-wrp .challenge-img-box span{
  max-height: 350px;
  height: 350px;
}
.tadas-main-wrp .challenge-main-wrapper .challenge-con-wrp .challenge-img-box span img{
  height: 100%;
  object-fit: contain;
  object-position: top;
}

.tadas-main-wrp .solution-main-wrapper {
  padding: 80px 0 50px;
}
.tadas-main-wrp .solution-main-wrapper .solution-con-wrp{
  padding-bottom: 50px;
}

.tadas-main-wrp .solution-main-wrapper .solution-con-wrp h1{
  color: #ffffff;
  font-family: "Red Hat Text";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 32px;
}
.tadas-main-wrp .solution-main-wrapper .solution-con-wrp p{
  color: #ffffff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tadas-main-wrp .solution-img-box span{
  max-width: 1920px;
  margin: 0 auto;
}



@media (max-width: 991px) {
  .tadas-main-wrp .abt-main-wrapper,
  .tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box span{
    height: 100%;
    min-height: 100%;
  }
  .tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box{
    padding-right: 0px;
  }
  .tadas-main-wrp .abt-main-wrapper .abt-content-box{
    padding-top: 0;
  }
  .tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box span img{
    object-position: top;
  }
  .tadas-main-wrp .challenge-main-wrapper .challenge-con-wrp .challenge-img-box{
    padding-left: 0;
  }
  .tadas-main-wrp .challenge-main-wrapper .challenge-content-box{
    padding-top: 0;
  }
  .tadas-main-wrp .banner-main-wrapper .banner-con-box .banner__title{
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .tadas-main-wrp .banner-main-wrapper .banner-con-box .row{
    flex-direction: column-reverse;
  }
  .tadas-main-wrp .abt-main-wrapper .abt-con-wrp .abt-img-box{
    padding-bottom: 50px;
  }
  .tadas-main-wrp .challenge-main-wrapper .challenge-content-box{
    padding-bottom: 50px;
  }
  .tadas-main-wrp .banner-main-wrapper{
    min-height: auto;
  }
  .tadas-main-wrp .banner-main-wrapper .banner-con-box .baner-image span{
    height: auto;
    min-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .tadas-main-wrp .banner-main-wrapper .banner-con-box .banner__title{
    background: linear-gradient(180deg, rgba(53, 10, 71, 0.33) 0%, #101010 100%);
  }

  .tadas-main-wrp .banner-main-wrapper .banner-con-box .banner__title .chips-container .chips{
    background: #A667FC;
    color: #fff;
  }


}


